<template>
  <div class="text-white border-t-4 border-blue-500 modal">
    <div class="px-4 py-5 bg-gray-700 border-b border-gray-800 sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-slate-100">Rename Layer</h3>
    </div>

    <div class="px-8 py-4 modal-content bg-zinc-900">
      <form
        :class="{ error: form.errors.get('form'), error: formFormHasErrors }"
        @submit.prevent="submit"
      >
        <label
          for="folderName"
          class="block text-sm font-medium leading-5 dark:text-white"
        >
          Layer Name
        </label>
        <div class="relative mt-1 rounded-md shadow-sm">
          <input
            ref="input"
            v-model="form.name"
            type="text"
            class="block w-full bg-black border-gray-200 rounded-md shadow-sm placeholder-neutral-400 focus:placeholder-neutral-100 text-neutral-400 focus:text-neutral-100 focus:bg-neutral-900 focus:ring-indigo-500 focus:border-indigo-500 focus-within:ring-1 dark:focus-within:border-transparent dark:focus-within:ring-2 dark:focus-within:ring-indigo-600"
            name="name"
            autofocus
          />
        </div>
        <div class="flex items-center justify-end mt-6 btn-group">
          <button class="text-white btn" type="button" @click="$emit('close')">
            Cancel
          </button>
          <button class="btn primary" type="button" @click.prevent="submit">
            Rename
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayerRename',

  props: {
    layer: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      form: new SparkForm({
        name: null
      }),
      formFormHasErrors: false
    }
  },
  computed: {
    readyToSubmit() {
      return this.form.name !== ''
    }
  },

  watch: {
    asset: {
      handler(asset) {
        this.form.name = this.layer.name
      },
      immediate: true
    }
  },

  mounted() {
    this.$refs.input.focus()
  },

  methods: {
    submit() {
      if (this.readyToSubmit) {
        this.formFormHasErrors = false
        this.form.startProcessing()

        this.$bus.$emit('layer:rename', {
          layer: this.layer,
          name: this.form.name
        })
        this.$emit('close')
      }
    }
  }
}
</script>
